import React, { Component } from 'react';
import $ from 'jquery';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import './App.scss';

import userId from './functions/userid';
import server from './functions/server';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

class App extends Component {

  constructor() {
    super();
    this.state = {
      load: false
    }
  }

  componentDidMount() {
    let id = userId();
    if (id == null) {
      this.setState({
        out: -100
      });
    } else {
      this.getInfo(id);
    }
  }

  getInfo(id) {
    let context = this;
    let url = server() + '/info/getinfobyid.php';
    let data = { id: id };
    $.ajax({ url, method: 'POST', data, beforeSend: (x) => {
          context.setState({ load: true, loadText: 'Loading' });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {}
      if (out === 1 || out === 0) {
        context.createInfo(id);
      }
      context.setState({
        out: out,
        load: false
      });
    });
  }

  createInfo(id) {
    let context = this;
    let url = server() + '/info/createinfobyid.php';
    let data = { id };
    $.ajax({ url, method: 'POST',  data, beforeSend: (x) => {
          context.setState({ load: true, loadText: 'Loading' });
      }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
      } catch(e) {}
      context.setState({ out: out, load: false });
    });
  }

  render() {
    if (this.state.out === -100) {
      window.location.href = "https://you.strackit.com/?redirectto=sms.strackit.com";
    } else if (typeof this.state.out !== "object") {
      return (
        <center>Loading</center>
      );
    }
    return (
      <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </Router>
    );
  }
}

export default App;
